import { REDIRECT_URL } from 'constants/index';
import { isWebview } from './isWebview';

// "proxy": "http://sso-test.mbrd.ru:8090", - add to package.json for local dev
// "proxy": "http://sso-dev.mbrd.ru:8090", - backend dev stand for testing locally

export interface ResponseError extends Error {
    description?: string;
    id?: string;
    messageCode?: string;
    status?: number;
}

const isEmpty = (data: unknown) => JSON.stringify(data) === '{}';

const request = async (method: string, url: string, data = {}, headers?: Record<string, unknown>) => {
    const serverUrl = `/api${url}`;
    const commonHeaders = {
        'Content-Type': 'application/json',
        Accept: '*/*',
    };

    const options: RequestInit = {
        method,
        headers: {
            ...commonHeaders,
            ...headers,
        },
        credentials: 'include',
    };

    if (method !== 'GET' && !isEmpty(data)) {
        options.body = JSON.stringify(data);
    }

    const response = await fetch(serverUrl, options);

    if (!response.ok) {
        const errorText = await response.json();
        const error: ResponseError = new Error(errorText.error);

        if (response.status === 400 && errorText.error === 'invalid_request' && !isWebview()) {
            return window.location.assign(REDIRECT_URL);
        }

        error.status = response.status;
        error.description = errorText.error_description;
        error.id = errorText.error_id;
        error.messageCode = errorText.error;

        throw error;
    }

    return response;
};

/**
 * todo добавлен для методов webauthn, требуется отрефакторить методо request и использовать его для всех api
 */
const fetcher = <Rs, Rq = undefined, H = undefined>(method: string, url: string, data?: Rq, headers?: H): Promise<Rs> =>
    fetch(url, {
        method,
        credentials: 'include',
        headers: {
            ...(headers || {}),
            'Content-Type': 'application/json',
        },
        ...(data && { body: JSON.stringify(data) }),
    }).then((response) =>
        response.json().then((jsonResponse: Rs) => {
            if (!response.ok) {
                throw new Error(JSON.stringify(jsonResponse));
            }

            return jsonResponse;
        })
    );

const get = (url: string, data = {}, headers?: Record<string, unknown>) => request('GET', url, data, headers);
const post = (url: string, data = {}, headers?: Record<string, unknown>) => request('POST', url, data, headers);
const postJSON = (...args: Parameters<typeof post>) => post(...args).then((response) => response && response.json());

/**
 * у ios 15.(5+) есть баг при повторном вызове authenticator (https://forums.developer.apple.com/forums/thread/710087)
 * проблема свзяна с вызовом fetch, в ios 16 она исправлена
 * для обхода проблемы для ios 15.5 fetch заменён на XMLHttpRequest
 */
const getChallenge = () => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.open('GET', '/webauthn/challenge', true);

    xhr.send();

    return new Promise<string>((resolve, reject) => {
        xhr.onload = () => {
            const { challenge } = xhr.response as { challenge: string };
            resolve(challenge);
        };

        xhr.onerror = () => {
            reject(new Error());
        };
    });
};
const verifyCredentials = (credentialData: Credential) =>
    fetcher<{ result: boolean }, Credential>('post', '/webauthn/publickey', credentialData).then((response) => response.result);
const login = (assertion: Credential) =>
    fetcher<{ [key: string]: string }, Credential>('post', '/api/v2/login', assertion).then((response) => response);

export const api = {
    customCookies: {},
    customHeaders: {},
    get,
    post,
    postJSON,
    getChallenge,
    verifyCredentials,
    login,
};
