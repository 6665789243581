import { getPh, sendGmtEvent } from 'utils/GTM/common';
import { successConfirmationOtpGTM } from 'utils/GTM/otpEvents';
import {
    ActionGroupGTM,
    AuthParamsGTM,
    EventActionGTM,
    EventCategoryGTM,
    EventContentGTM,
    EventGTM,
    EventLabelsGTM,
    AuthTypeGTM,
    PageTypeGTM,
    UserAuthGTM,
    ScreenNamesGTM,
    DoAuthPropsGTM,
    DoAuthGTM,
} from './types';

const baseAuthParams = {
    event: EventGTM.MTS_PAYMENTS_AUTH,
    eventCategory: EventCategoryGTM.AUTH_FORM,
    pageType: PageTypeGTM.SSO_AUTH,
    actionGroup: ActionGroupGTM.NON_INTERACTIONS,
};

/** Успешная авторизация по номеру телефона
 *  uth_form-form_confirmed-uspeshnaya_avtorizaciya-pin | auth_form-form_confirmed-uspeshnaya_avtorizaciya */
export const successfulAuthGTM = ({ screenName, login, isBio, eventContextGTM }: AuthParamsGTM) =>
    sendGmtEvent({
        ...baseAuthParams,
        screenName,
        eventContext: eventContextGTM || null,
        eventAction: EventActionGTM.FORM_CONFIRMED,
        eventLabel: EventLabelsGTM.SUCCESSFUL_AUTH,
        userAuth: UserAuthGTM.AUTHENTICATED,
        eventContent: isBio ? EventContentGTM.BIO : EventContentGTM.OTP,
        ph: getPh(login),
    });

/** Провал авторизации
 * auth_form-error_show-vhod_otm */
export const unsuccessfulAuthGTM = ({ screenName, login, eventContextGTM, description, messageCode, message }: AuthParamsGTM) =>
    sendGmtEvent({
        ...baseAuthParams,
        screenName,
        eventContext: eventContextGTM || null,
        eventAction: EventActionGTM.ERROR_SHOW,
        eventLabel: EventLabelsGTM.ENTRY_OTM,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        ph: getPh(login),
        eventContent: description || null,
        filterName: messageCode || message || null,
    });

/** матчинг возможных типов авторизации */
const matchAuthType = {
    [AuthTypeGTM.CONFIRMATION_OTP]: (login: string) => successConfirmationOtpGTM({ login }),
    [AuthTypeGTM.NICKNAME]: (login: string) => successfulAuthGTM({ login, screenName: ScreenNamesGTM.NAME_OR_NICKNAME }),
    [AuthTypeGTM.SIGN_POLICY]: (login: string) => successfulAuthGTM({ login, screenName: ScreenNamesGTM.POLICY }),
    [AuthTypeGTM.ADDITIONAL]: (login: string, { isTypeCard }: DoAuthPropsGTM) => {
        successfulAuthGTM({
            login,
            screenName: isTypeCard ? ScreenNamesGTM.CONFIRMATION_CARD : ScreenNamesGTM.CONFIRMATION_PASSPORT,
        });
    },
    [AuthTypeGTM.RESTRICTED]: (login: string, { eventContextGTM }: DoAuthPropsGTM) => {
        successfulAuthGTM({ login, eventContextGTM, screenName: ScreenNamesGTM.WARNING });
    },
};

export const doAuthGTM: DoAuthGTM = (login: string, gtmProps) => {
    if (matchAuthType[gtmProps.authTypeGTM]) {
        matchAuthType[gtmProps.authTypeGTM](login, gtmProps);
    }
};
