import {
    ActionGroupGTM,
    EventActionGTM,
    EventCategoryGTM,
    EventGTM,
    EventLabelsGTM,
    PageTypeGTM,
    ParamsGTM,
    ScreenNamesGTM,
    UserAuthGTM,
} from 'utils/GTM/types';
import { baseFurtherParams, baseShowScreenParams, getPh, sendGmtEvent } from 'utils/GTM/common';

const baseNickNameParams = {
    eventCategory: EventCategoryGTM.AUTH_FORM,
    screenName: ScreenNamesGTM.LOGIN,
    event: EventGTM.MTS_MONEY_AUTH,
};

/** показ экрана  ввод имени в "Добро пожаловать в экосистему МТС"
 * scrn
 * */
export const showScreenMTSEcosystemGTM = () =>
    sendGmtEvent({
        ...baseShowScreenParams,
        eventLabel: EventLabelsGTM.LOGIN,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        screenName: ScreenNamesGTM.LOGIN,
    });

/** onBlur поля "Имя" на экране "Добро пожаловать в экосистему МТС"
 * auth_form-form_add-login
 * */
export const nicknameOnBlurGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...baseNickNameParams,
        eventAction: EventActionGTM.FORM_ADD,
        eventLabel: EventLabelsGTM.LOGIN,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        pageType: PageTypeGTM.SSO_AUTH,
        actionGroup: ActionGroupGTM.INTERACTIONS,
        ph: getPh(login),
    });

/** Событие срабатывает каждый раз при некорректном заполнении поля "Имя" на экране "Добро пожаловать в экосистему МТС"
 * auth_form-error_show-nekorretnoe_imya
 * */
export const nicknameHandleErrorGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...baseNickNameParams,
        eventAction: EventActionGTM.ERROR_SHOW,
        eventLabel: EventLabelsGTM.WRONG_NAME,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        pageType: PageTypeGTM.SSO_AUTH,
        actionGroup: ActionGroupGTM.NON_INTERACTIONS,
        ph: getPh(login),
    });

/** Не успешный ввод номера карты или паспорта Клик по кнопке "Далее"
 *  auth_form-button_click-dalee-vvod_pasporta     */
export const furtherNickNameGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...baseNickNameParams,
        ...baseFurtherParams,
        ph: getPh(login),
    });
