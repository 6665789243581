import { baseShowScreenParams, getPh, sendGmtEvent } from 'utils/GTM/common';
import {
    ActionGroupGTM,
    EventGTM,
    EventActionGTM,
    EventCategoryGTM,
    PageTypeGTM,
    ParamsGTM,
    ScreenNamesGTM,
    TouchPointGTM,
    UserAuthGTM,
    EventLabelsGTM,
    EventContentGTM,
} from 'utils/GTM/types';

const basePolicyParams = {
    event: EventGTM.MTS_PAYMENTS_AUTH,
    eventCategory: EventCategoryGTM.AUTH_FORM,
    eventAction: EventActionGTM.BUTTON_CLICK,
    userAuth: UserAuthGTM.UNAUTHENTICATED,
    screenName: ScreenNamesGTM.LOGIN,
    pageType: PageTypeGTM.SSO_AUTH,
    actionGroup: ActionGroupGTM.INTERACTIONS,
    touchPoint: TouchPointGTM.WEB,
};

/** Клик по ссылке условия доступа //
 * auth_form-button_click- нету еще */
export const accessConditionsClickGtm = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...basePolicyParams,
        eventLabel: EventLabelsGTM.ACCESS_CONDITIONS,
        eventContent: EventContentGTM.CONFIDENCIAL,
        ph: getPh(login),
    });

/** Клик по ссылке политика конфиденциальности
 * auth_form-click-politika_konfidencialnosti  */
export const privacyPolicyClickGtm = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...basePolicyParams,
        eventLabel: EventLabelsGTM.POLYCI_CONFIDENCIAL,
        ph: getPh(login),
    });

/** Клик по кнопке Принять на политике конфиденциальности
 * auth_form-button_click-prinyat-konfidencialnost */
export const acceptPolicyGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...basePolicyParams,
        eventLabel: EventLabelsGTM.ACCEPT,
        eventContent: EventContentGTM.CONFIDENCIAL,
        ph: getPh(login),
    });

/** Переход на экран Policy
 * scrn
 * */
export const showScreenPolicyGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...baseShowScreenParams,
        eventLabel: EventLabelsGTM.POLYCI_CONFIDENCIAL,
        screenName: ScreenNamesGTM.LOGIN,
        ph: getPh(login),
    });
