import { getPh, sendGmtEvent } from 'utils/GTM/common';
import {
    ActionGroupGTM,
    ButtonTextsGTM,
    ErrorsParamsGTM,
    EventActionGTM,
    EventCategoryGTM,
    EventContentGTM,
    EventContext,
    EventGTM,
    EventLabelsGTM,
    FiveMinPopUpClickPropsGTM,
    PagePropsGTM,
    PageTypeGTM,
    ParamsGTM,
    ScreenNamesGTM,
    ShowPopUpBlocFiveMinParamsGTM,
    UserAuthGTM,
} from 'utils/GTM/types';
import { modalsData } from 'lib/modalsData';

const showPopUpBaseParams = {
    eventCategory: EventCategoryGTM.AUTH_FORM,
    pageType: PageTypeGTM.SSO_AUTH,
    actionGroup: ActionGroupGTM.NON_INTERACTIONS,
};

const clickPopUpBaseParams = {
    eventCategory: EventCategoryGTM.AUTH_FORM,
    eventAction: EventActionGTM.BUTTON_CLICK,
    pageType: PageTypeGTM.SSO_AUTH,
    actionGroup: ActionGroupGTM.INTERACTIONS,
    userAuth: UserAuthGTM.UNAUTHENTICATED,
};

/** Показ топапа при возникновении ошибки соединения
 * auth_form-error_show-oshibka_soedineniya */
export const showPopUpConnectionErrorGTM = ({ login, messageCode, description }: ErrorsParamsGTM) => {
    return sendGmtEvent({
        ...showPopUpBaseParams,
        event: EventGTM.MTS_MONEY_AUTH,
        eventAction: EventActionGTM.ERROR_SHOW,
        eventLabel: EventLabelsGTM.CONNECTION_ERROR,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        screenName: ScreenNamesGTM.LOGIN,
        eventContent: description || null,
        filterName: messageCode || null,
        ph: getPh(login),
    });
};

/** Показ попапа Попробуйте войти через 5 минут
 * auth_form-popup_show-poprobuite_cherez_5_minut
 * */
const showPopUpBlocFiveMinGTM = ({ eventContent, login, eventContext, filterName }: ShowPopUpBlocFiveMinParamsGTM) => {
    return sendGmtEvent({
        ...showPopUpBaseParams,
        event: EventGTM.MTS_MONEY_AUTH,
        eventAction: EventActionGTM.POPUP_SHOW,
        eventLabel: EventLabelsGTM.TRY_AFTER_5_MIN,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        screenName: ScreenNamesGTM.LOGIN,
        eventContent,
        eventContext: eventContext || null,
        filterName: filterName || null,
        ph: getPh(login),
    });
};

/** Показ попапа Вы клиент МТС Банка ?
 * auth_form-popup_show-vy_klient_banka
 * */
const showPopAreYouClientBankGTM = ({ login }: ParamsGTM) =>
    sendGmtEvent({
        ...showPopUpBaseParams,
        event: EventGTM.MTS_PAYMENTS_AUTH,
        eventAction: EventActionGTM.POPUP_SHOW,
        eventLabel: EventLabelsGTM.YOU_ARE_BANK_CLIENT,
        userAuth: UserAuthGTM.UNAUTHENTICATED,
        screenName: ScreenNamesGTM.CONFIRM,
        ph: getPh(login),
    });

/** Показ попапа Нужна проверка
 * auth_form-popup_show-nuzhna_proverka */
export const showPopUpNeedVerificationGTM = () =>
    sendGmtEvent({
        ...showPopUpBaseParams,
        event: EventGTM.MTS_PAYMENTS_AUTH,
        eventAction: EventActionGTM.POPUP_SHOW,
        eventLabel: EventLabelsGTM.NEED_CHECK,
        screenName: ScreenNamesGTM.WARNING,
    });

/** Клики по кнопкам попапа Вы клиент МТС Банка ?
 * auth_form-button_click-klient_banka
 * auth_form-button_click-ne_klient_banka
 * */
export const clickButtonsAreYouClientBankGTM = (yesButton?: boolean, login?: string) =>
    sendGmtEvent({
        ...clickPopUpBaseParams,
        event: EventGTM.MTS_PAYMENTS_AUTH,
        eventLabel: yesButton ? EventLabelsGTM.YES : EventLabelsGTM.NO,
        screenName: ScreenNamesGTM.CONFIRM,
        ph: getPh(login),
    });

/** Клики по кнопкам попапа ошибок соединения
 * auth_form-button_click-poprobovat_esche_raz
 * auth_form-button_click-pozvonit_v_bank
 * */
export const clickErrorPopUpGTM = (retry: boolean, login: string | undefined) =>
    sendGmtEvent({
        ...clickPopUpBaseParams,
        event: EventGTM.MTS_MONEY_AUTH,
        eventLabel: retry ? EventLabelsGTM.TRY_AGAIN : EventLabelsGTM.CALL_TO_BANK,
        screenName: ScreenNamesGTM.LOGIN,
        ph: getPh(login),
    });

/** Клики по кнопкам попапа Нужна проверка
 * auth_form-button_click-otdeleniya_na_karte-nuzhna_proverka
 * auth_form-button_click-pozvonit_v_bank-nuzhna_proverka
 * */
export const clickNeedVerificationGTM = (phoneButton: boolean, login: string | undefined) =>
    sendGmtEvent({
        ...clickPopUpBaseParams,
        event: EventGTM.MTS_PAYMENTS_AUTH,
        eventLabel: phoneButton ? EventLabelsGTM.CALL_TO_BANK : EventLabelsGTM.OFFICES_MAP,
        screenName: ScreenNamesGTM.WARNING,
        eventContent: EventLabelsGTM.NEED_CHECK,
        ph: getPh(login),
    });

/** Клики по кнопке попапа Попробуйте войти через 5 минут
 * auth_form-button_click-voiti_s_drugim_nomerom-poprobuite_cherez
 * */
export const clickFiveMinPopUpGTM = ({ login, callToBank, eventContent }: FiveMinPopUpClickPropsGTM) =>
    sendGmtEvent({
        ...clickPopUpBaseParams,
        event: EventGTM.MTS_MONEY_AUTH,
        screenName: ScreenNamesGTM.LOGIN,
        eventLabel: callToBank ? EventLabelsGTM.CALL_TO_BANK : EventLabelsGTM.ANOTHER_NUMBER,
        eventContent,
        ph: getPh(login),
    });

export const gtmPage = ({ heading, message, login, errorId }: PagePropsGTM): void => {
    const { loginLock, loginLock2, smsLimit, identityLock, smsNotFound, smsLock } = modalsData;

    switch (heading) {
        case loginLock.heading:
            if (loginLock.message === message) {
                showPopUpBlocFiveMinGTM({ login, filterName: errorId, eventContent: EventContentGTM.TEMPORARILY_BLOCK });
                break;
            }
            if (loginLock2.message === message) {
                showPopUpBlocFiveMinGTM({
                    login,
                    filterName: errorId,
                    eventContent: EventContentGTM.OFTEN_OTP,
                    eventContext: EventContext.OTP_PAGE,
                });
                break;
            }
            if (smsLimit.message === message) {
                showPopUpBlocFiveMinGTM({
                    login,
                    filterName: errorId,
                    eventContent: EventContentGTM.INCORRECT_OTP,
                    eventContext: EventContext.OTP_PAGE,
                });
                break;
            }
            if (identityLock.message === message) {
                showPopUpBlocFiveMinGTM({
                    login,
                    filterName: errorId,
                    eventContent: EventContentGTM.INCORRECT_DATA,
                    eventContext: EventContext.CONFIRMATION_PAGE,
                });
                break;
            }
            break;
        case smsNotFound.heading:
            showPopAreYouClientBankGTM({ login });
            break;
        case smsLock.heading:
            showPopUpNeedVerificationGTM();
            break;
        default:
    }
};

interface OnGtmClickProps {
    text: string;
    errorId?: string;
    heading?: string;
    login?: string;
    message?: string;
}
export const onGtmClick = ({ text, errorId, message, heading, login }: OnGtmClickProps): void => {
    const { loginLock, loginLock2, smsLimit, identityLock, smsNotFound, smsLock, networkError } = modalsData;

    const events = (callToBank: boolean) => {
        if (loginLock2.message === message) {
            clickFiveMinPopUpGTM({ callToBank, login, eventContent: EventContentGTM.OFTEN_OTP });
        }
        if (loginLock.message === message) {
            clickFiveMinPopUpGTM({ callToBank, login, eventContent: EventContentGTM.TEMPORARILY_BLOCK });
        }
        if (smsLimit.message === message) {
            clickFiveMinPopUpGTM({ callToBank, login, eventContent: EventContentGTM.INCORRECT_OTP });
        }
        if (identityLock.message === message) {
            clickFiveMinPopUpGTM({ callToBank, login, eventContent: EventContentGTM.INCORRECT_DATA });
        }
        if (smsLock.heading === heading) {
            clickNeedVerificationGTM(callToBank, login);
        }
        if (heading === networkError.heading) {
            clickErrorPopUpGTM(false, errorId);
        }
    };

    switch (text) {
        case ButtonTextsGTM.CallToBank:
            events(true);
            break;
        case ButtonTextsGTM.OfficesMaps:
            clickNeedVerificationGTM(false, login);
            break;
        case ButtonTextsGTM.AnotherNumber:
            events(false);
            break;
        case ButtonTextsGTM.Yes:
            if (heading === smsNotFound.heading) {
                clickButtonsAreYouClientBankGTM(true);
            }
            break;
        case ButtonTextsGTM.No:
            clickButtonsAreYouClientBankGTM();
            break;
        case ButtonTextsGTM.Retry:
            clickErrorPopUpGTM(true, login);
            break;
        default:
    }
};
